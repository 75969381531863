import React, { lazy , Suspense } from 'react';
import {BrowserRouter,Route,Routes} from 'react-router-dom';


// <<<<<<!----------Contexts Data------------!>>>>>>>>>>>
import { movieNewsCardData } from './TeluguContext/tmdbContext.js'

// <<<<<<!-------------------------------------!>>>>>>>>>>>


import './App.css';

import ToneLoader from './Components/commonComponents/Loaders/ToneLoader/index.js'



// <<<<<<!----------TELUGU ONE HOME PAGE ROUTES------------!>>>>>>>>>>>
const ToneHome = lazy(() => import('./Components/ToneHome/index.js') )
// <<<<<<!--------------------------------------!>>>>>>>>>>>>>>>>>>>>>>



//<<<<<<<!----------NEWS PAGE ROUTES------------!>>>>>>>>>>>
const News =lazy(() => import('./Components/News/index'))
const NewsMain = lazy(() => import('./Components/News/newsMain/index.js'))
const CategoryNews  = lazy(() => import('./Components/News/categoryNewsPage/index.js'))
const NewsContent = lazy(() => import('./Components/News/newsContent/index.js'))
const RssFeedsMain = lazy(() => import('./Components/News/rssFeeds/index.js'))
// <<<<<<!--------------------------------------!>>>>>>>>>>>


 
// <<<<<<!----------TMDB PAGE ROUTES------------!>>>>>>>>>>>
const Tmdb  = lazy(() => import ('./Components/Tmdb/index.js'))
const TmdbPages  = lazy(() => import ('./Components/TmdbPages/index'))
const TmdbMovieNews = lazy(() => import('./Components/TmdbPages/tmdbMovieNews/index'))
const  TmdbMovieReviews = lazy(() => import('./Components/TmdbPages/tmdbMovieReviews/index.js'))
const TmdbMovieReviewsMain  = lazy(() => import('./Components/TmdbPages/tmdbMovieReviews/tmdbMoviereviewsMain'))
const TmdbMovieReviewsDetails = lazy(() => import('./Components/TmdbPages/tmdbMovieReviews/tmdbMovieReviewsDetails'))
const TmdbMovieCelebrities = lazy(() => import('./Components/TmdbPages/tmdbMovieCelebrities/index.js'))
const  TmdbVideoGossips = lazy(() => import('./Components/TmdbPages/tmdbVideoGossips/index.js'))
const  TmdbVideoGossipsMain = lazy(() => import('./Components/TmdbPages/tmdbVideoGossips/tmdbVideoGossipsMain'))
const TmdbVideoGossipDetails = lazy(() => import('./Components/TmdbPages/tmdbVideoGossips/tmdbVideoGossipDetails/index.js'))
const  TmdbMovieNewsContent = lazy(() =>import('./Components/TmdbPages/tmdbMovieNewsContent/index.js'))
// <<<<<<!--------------------------------------!>>>>>>>>>>>



// <<<<<!----------MOVIES ROUTES------------!>>>>>>>>>>>
const Movies  = lazy(() => import('./Components/Movies/index.js'))
const  MoviesHome = lazy(() => import('./Components/Movies/moviesHome/index.js'))
const MoviesCategory = lazy(() => import('./Components/Movies/moviesCategory/index.js'))
const MovieDetails  = lazy(() => import('./Components/Movies/movieDetails/index.js'))
// <<<<<<!--------------------------------------!>>>>>>>>>>>




// <<<<<!----------PHOTOS ROUTES------------!>>>>>>>>>>>
const Photos =lazy(() =>   import('./Components/Photos/index.js'))
const PhotosHome =lazy(() =>   import('./Components/Photos/PhotosHome/index.js'))
const PhotosCategory =lazy(() => import('./Components/Photos/PhotosCategory/index.js'))
const PhotosCategoryPrimary=lazy(() => import('./Components/Photos/PhotosCategoryPrimary/index.js'))
const PhotosCategoryIndividual=lazy(() => import('./Components/Photos/PhotosCategoryIndividual/index.js'))
const PhotosCategoryShowCase=lazy(() => import('./Components/Photos/PhotosCategoryShowCase/index.js'))
// <<<<<<!--------------------------------------!>>>>>>>>>>>


// <<<<<!----------DEVOTIONAL ROUTES------------!>>>>>>>>>>>

const Devotional=lazy(()=> import('./Components/Devotional/index.js'))
const DevotionalHome=lazy(() => import('./Components/Devotional/DevotionalHome/index.js'))
const DevotionalContentsPage=lazy(() =>import('./Components/Devotional/DevotionalContentsPage/index.js'))
const DevotionalSubPages=lazy(() =>import('./Components/Devotional/DevotionalSubPages/index.js'))
const DevotionalVideoList=lazy(() => import('./Components/Devotional/DevotionalVideoList/index.js'))
const DevotionalVideos=lazy(() => import('./Components/Devotional/DevotionalVideos/index.js'))
const DevotionalVideo=lazy(() => import('./Components/Devotional/DevotionalVideo/index.js'))
const DevotionalContent=lazy(() => import('./Components/Devotional/DevotionalContent/index.js'))
const DevotionalWeekly=lazy(() => import('./Components/Devotional/DevotionalWeekly/index.js'))
const DevotionalTithi=lazy(() => import('./Components/Devotional/DevotionalTithi/index.js'))
// <<<<<<!--------------------------------------!>>>>>>>>>>>



// <<<<<!----------GRANDALAYAM ROUTES------------!>>>>>>>>>>>
 const Grandalayam =lazy(() => import('./Components/Grandalayam/index.js'))
 const GrandalayamHome =lazy(() => import('./Components/Grandalayam/GrandalayamHome/index.js'))
 const GrandalayamCategory=lazy(() => import('./Components/Grandalayam/GrandalayamCategory/index.js'))
 const GrandalayamNovels=lazy(() => import('./Components/Grandalayam/GrandalayamCategory/GrandalayamNovels/index.js'))
 const GrandalyamNovelDetails=lazy(() =>import('./Components/Grandalayam/GrandalayamNovelDetails/index.js'))
 const GrandalayamWriters=lazy(() => import('./Components/Grandalayam/GrandalayamWriters/index.js'))
 // <<<<<<!--------------------------------------!>>>>>>>>>>>


// <<<<<!---------- VANITHA ROUTES------------!>>>>>>>>>>>
const Vanitha=lazy(() =>import('./Components/Vanitha/index.js'))
const VanithaHome=lazy(() => import('./Components/Vanitha/VanithaHome/index.js'))
//<<<<<<!------------------------------------------!>>>>>>>>>>>




// <<<<<<!----------PAGE NOT FOUND ROUTES------------!>>>>>>>>>>>>>
const PageNotFound=lazy(() => import ('./Components/NotFound/index.js'))
//<<<<<<!------------------------------------------!>>>>>>>>>>>>>>





  




function App() { 
  
  return (
    <div className="App d-flex flex-column align-items-center">
        <BrowserRouter>
        <Suspense fallback={<ToneLoader />}>
        <Routes>
                <Route exact  path="/" element={<ToneHome />} />

                <Route path="/news" element={<News />}>
                                    <Route index  element={<NewsMain />} />
                                    <Route path="/news/latestnews" element={<CategoryNews catId={25} cat={"LatestNews"} />} />
                                    <Route path="/news/politics" element={<CategoryNews catId={39} cat={"Politics"} />} />
                                    <Route path="/news/lifestyle" element={<CategoryNews catId={35} cat={"lifestyle"} />} />
                                    <Route path="/news/healthnews" element={<CategoryNews catId={34} cat={"healthnews"} />} />
                                    <Route path="/news/analyticsnews" element={<CategoryNews catId={45} cat={"analyticsnews"} />} />
                                    <Route path="/news/content/:contentText"   element={<NewsContent />} />
                                    <Route path="/news/rssdetails"  element={<RssFeedsMain  />} />
                  </Route>


                  <Route path="/tmdb" element={<Tmdb />}  />

                  <Route path="/tmdb/pages" element={<TmdbPages />}>
                      <Route path="latestnews" element={<TmdbMovieNews subRoot={"Latest News"} movieNewsCardData={movieNewsCardData.LatestNews} />} />
                      <Route path="featurednews" element={<TmdbMovieNews subRoot={"Featured News"} movieNewsCardData={movieNewsCardData.FeaturedNews}/>} />
                      <Route path="bollywoodnews" element={<TmdbMovieNews subRoot={"Bollywood News"} movieNewsCardData={movieNewsCardData.BollyWoodNews}/>} />
                      <Route path="gossips" element={<TmdbMovieNews subRoot={"GOSSIPS"} movieNewsCardData={movieNewsCardData.Gossips}/>} />
                      <Route path="tvnews" element={<TmdbMovieNews subRoot={"Tv News"} movieNewsCardData={movieNewsCardData.TvNews}/>} />
                      <Route path="reviews" element={<TmdbMovieReviews />} >
                         <Route path="main" element={<TmdbMovieReviewsMain />} />
                         <Route path="details" element={<TmdbMovieReviewsDetails />} />
                     </Route>
                      
                    <Route path="celebrities" element={<TmdbMovieCelebrities />} />
                      <Route path="videogossips" element={<TmdbVideoGossips  />} >
                        <Route path="main" element={<TmdbVideoGossipsMain />} />
                        <Route path="details" element={<TmdbVideoGossipDetails />} />
                      </Route>

                      <Route path="latestnews/content" element={<TmdbMovieNewsContent subRoot={"Latest News"} />} />
                      <Route path="bollywoodnews/content" element={<TmdbMovieNewsContent subRoot={"Latest News"} />} />
                      <Route path="featurednews/content" element={<TmdbMovieNewsContent subRoot={"Latest News"} />} />
                      <Route path="tvnews/content" element={<TmdbMovieNewsContent subRoot={"Latest News"} />} />
                              </Route>

                  <Route path="/movies" element={<Movies />}>
                        <Route index element={<MoviesHome  homeActiveLink={"Home"} />} />
                        <Route path="latest" element={<MoviesCategory homeActiveLink={"Latest"}  />}  />   
                        <Route path="action" element={<MoviesCategory homeActiveLink={"Action"}  />}  />   
                        <Route path="classics" element={<MoviesCategory homeActiveLink={"Classics"} />}  />   
                        <Route path="comedy" element={<MoviesCategory  homeActiveLink={"Comedy"} />}  />   
                        <Route path="family" element={<MoviesCategory homeActiveLink={"Family"}  />}  />   
                        <Route path="romantic" element={<MoviesCategory homeActiveLink={"Romantic"}  />}  />   
                        <Route path="love" element={<MoviesCategory  homeActiveLink={"Love"} />}  />   
                        <Route path="shortfilms" element={<MoviesCategory homeActiveLink={"ShortFilms"}   />}  />    
                        <Route path="latest/details/:id" element={<MovieDetails homeActiveLink={"Latest"} />}  />   
                        <Route path="action/details/:id" element={<MovieDetails homeActiveLink={"Action"}  />}  />   
                        <Route path="classics/details/:id" element={<MovieDetails homeActiveLink={"Classics"} />}  />   
                        <Route path="comedy/details/:id" element={<MovieDetails homeActiveLink={"Comedy"}  />}  />   
                        <Route path="family/details/:id" element={<MovieDetails  homeActiveLink={"Family"} />}  />   
                        <Route path="romance/details/:id" element={<MovieDetails homeActiveLink={"Romantic"}  />}  />   
                        <Route path="love/details/:id" element={<MovieDetails />} homeActiveLink={"Love"}   />   
                        <Route path="shortfilms/details/:id" element={<MovieDetails homeActiveLink={"ShortFilms"} />}/>   
            
                  </Route>

                              <Route path="/photos" element={<Photos />}>

                                    <Route index element={<PhotosHome />} />
                                    <Route  path='/photos/actress' element={<PhotosCategory  />} >
                                          <Route index  element={<PhotosCategoryPrimary />} />
                                          <Route path="/photos/actress/individual"  element={<PhotosCategoryIndividual />} />
                                          <Route path="/photos/actress/individualShowCase" element={<PhotosCategoryShowCase />} />
                                    </Route>
                                    <Route  path='/photos/actors' element={<PhotosCategory  />} >
                                          <Route index  element={<PhotosCategoryPrimary />} />
                                          <Route path="/photos/actors/individual"  element={<PhotosCategoryIndividual />} />
                                          <Route path="/photos/actors/individualShowCase" element={<PhotosCategoryShowCase />} />
                                    </Route>
                                    <Route  path='/photos/events' element={<PhotosCategory   />} >
                                                <Route index  element={<PhotosCategoryPrimary />} />
                                          <Route path="/photos/events/individual"  element={<PhotosCategoryIndividual />} />
                                          <Route path="/photos/events/individualShowCase" element={<PhotosCategoryShowCase />} />
                                    </Route>
                                    <Route  path='/photos/wallpapers' element={<PhotosCategory  />} >
                                                <Route index  element={<PhotosCategoryPrimary />} />
                                          <Route path="/photos/wallpapers/individual"  element={<PhotosCategoryIndividual />} />
                                          <Route path="/photos/wallpapers/individualShowCase" element={<PhotosCategoryShowCase />} />
                                    </Route>
                                    <Route  path='/photos/kingsize' element={<PhotosCategory  />} >
                                          <Route index  element={<PhotosCategoryPrimary />} />
                                          <Route path="/photos/kingsize/individual"  element={<PhotosCategoryIndividual />} />
                                          <Route path="/photos/kingsize/individualShowCase" element={<PhotosCategoryShowCase />} />
                                    </Route>
                                    <Route  path='/photos/others' element={<PhotosCategory  />} >
                                                <Route index  element={<PhotosCategoryPrimary />} />
                                          <Route path="/photos/others/individual"  element={<PhotosCategoryIndividual />} />
                                          <Route path="/photos/others/individualShowCase" element={<PhotosCategoryShowCase />} />
                                    </Route>
                                    <Route  path='/photos/movies' element={<PhotosCategory  />} >
                                          <Route index  element={<PhotosCategoryPrimary />} />
                                          <Route path="/photos/movies/individual"  element={<PhotosCategoryIndividual />} />
                                          <Route path="/photos/movies/individualShowCase" element={<PhotosCategoryShowCase />} />
                                    </Route>
                              </Route>

                              <Route path="/devotional" element={<Devotional />}>

                                    <Route index element={<DevotionalHome />} />
                                    
                                    <Route path="contents/" element={<DevotionalContentsPage />} />
                                          
                                    <Route path="subpages/" element={<DevotionalSubPages />} />

                                    <Route path="videolist/" element={<DevotionalVideoList />} />

                                    <Route path="videos/" element={<DevotionalVideos />} />

                                    <Route path="video/" element={<DevotionalVideo />} />

                                    <Route path="content/" element={<DevotionalContent />} />
                                    
                                    <Route path="weekly/" element={<DevotionalWeekly />} />

                                    <Route path="tithi/" element={<DevotionalTithi />} />
                                    

                              </Route> 

                              <Route path="/grandalayam" element={<Grandalayam />}>
                                 <Route index element={<GrandalayamHome />} />
                                 <Route path='novels'  element={<GrandalayamCategory />} >
                                      <Route index element={<GrandalayamNovels />} />
                                       <Route path='details/:novel' element={<GrandalyamNovelDetails />} />
                                 </Route>
                        
                                 <Route path='writers/publications' element={<GrandalayamWriters />} />
                                 <Route path='writers/history' element={<GrandalayamWriters />} />
                                 <Route path='writers/individual' element={<GrandalayamWriters />} />
                                

                              </Route>

                              <Route path="/vanitha" element={<Vanitha />}>
                                    <Route index element={<VanithaHome />}   />
                              </Route>
             { /*<Route path="/shortFilms" element={<ShortFilms />}></Route>
              <Route path="/videos" element={<Videos />}></Route>
              <Route path="/recipes" element={<Recipes />}></Route>
              <Route path="/astrology" element={<Astrology />}></Route>
              <Route path="/comedy" element={<Comedy />}></Route>
              <Route path="/health" element={<Health />}></Route>
              <Route path="/greetings" element={<Greetings/>}></Route>
              <Route path="/charity" element={<Charity />}></Route>
              <Route path="/nri" element={<Nri />}></Route>
              <Route path="/help" element={<Help />}></Route>
              <Route path="/siteMap" element={<SiteMap />}></Route>
              <Route path="/siteTour" element={<SiteTour />}></Route>
              <Route path="/recommend" element={<Recommend />}></Route>
              <Route path="/faq" element={<Faq />}></Route>
              <Route path="/contactInfo" element={<ContactInfo />}></Route>
              <Route path="/feedback" element={<FeedBack/>}></Route>
               */}

              <Route path="*" Component={PageNotFound} /> 

          </Routes>
          </Suspense>
          
       </BrowserRouter>
     
    </div>
  );
}



export default App;














